import { User as Auth0User } from "@auth0/auth0-react";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { ReactNode } from "react";
import { Permissions } from "../domain/tenant";
import { Department, Organisation } from "./company";
import { CourseCompletionStatus } from "./v2/conversational-training-workflow";
import { AssignmentType, CourseProgressStatus } from "./v2/course";

export const TENANT_INFO_CLAIM = "https://talkout.com/tenantInfo";
export const PASSWORD_CHANGE_REQUIRED_CLAIM = "https://talkout.com/passwordChangeRequired";

export type Role = "user" | "creator" | "manager" | "orgAdmin" | "admin";
export const ROLES: Role[] = ["user", "creator", "manager", "orgAdmin", "admin"];

export type TenantInfo = {
  [tenantId: string]: {
    permissions: string[];
    organisationId: string;
    organisationUserId: string;
  };
};

export type DecodedToken = JwtPayload & {
  permissions: Permissions;
  organisationUserId: string;
  [TENANT_INFO_CLAIM]: TenantInfo;
  [PASSWORD_CHANGE_REQUIRED_CLAIM]?: boolean;
};

export interface Identity {
  platformUserId?: string;
  token: string;
  tenantInfo: TenantInfo;
  passwordChangeRequired?: boolean;
  permissions: string[];
}

export class User {
  token: string;
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
  permissions: string[];
  tenantInfo: TenantInfo;

  constructor(
    token: string,
    email: string,
    email_verified: boolean,
    name: string,
    nickname: string,
    picture: string,
    sub: string,
    updated_at: string,
    permissions: string[],
    tenantInfo: TenantInfo
  ) {
    this.token = token;
    this.email = email;
    this.email_verified = email_verified;
    this.name = name;
    this.nickname = nickname;
    this.picture = picture;
    this.sub = sub;
    this.updated_at = updated_at;
    this.permissions = permissions;
    this.tenantInfo = tenantInfo;
  }

  static fromAuth0UserAndToken(json: Auth0User, token: string): User {
    const decodedToken = jwtDecode<DecodedToken>(token);

    const permissions = decodedToken["permissions"] || [];
    const tenantInfo: TenantInfo = decodedToken[TENANT_INFO_CLAIM] || {};

    if (typeof json === "string") {
      const jsonUser = JSON.parse(json);
      return { ...jsonUser, permissions, token, tenantInfo };
    } else {
      let user = Object.create(User.prototype);

      const jsonUser = Object.assign(user, json);
      return { ...jsonUser, permissions, token, tenantInfo };
    }
  }
}

export enum InvitationStatus {
  accepted = "accepted",
  none = "none",
  requested = "requested",
  onboardedProgress = "onboardedProgress",
  pending = "pending"
}

export interface TenantUserResponse {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  phoneNumber: string;
  role: Role;
  jobRole?: string;
  dateOfBirth?: string;
  startDate?: string;
  terminationDate?: string;
  organisationId: string;
  departmentId: string;
  platformUserId?: string;
  organisationName: string;
  departmentName: string;
  avatarUrl?: string;
  invitationStatus?: InvitationStatus;
  isMHFA?: boolean;
  isOnboardingComplete?: boolean;
  invitationId?: string;
  services?: string[];
  isSuspended?: boolean;
  sourceIntegrationId?: string;
  contentLanguageCode?: string;
  externalId?: string;
  _updatedOn?: string;
  lastActive?: {
    lastActiveOn?: string;
    platform?: "ios" | "android" | "web";
    appVersion?: string;
  };
  customFields?: Record<string, CustomFieldValue>;
}
export interface TenantUserPayload {
  id?: string;
  tenant: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  phoneNumber: string;
  role: string;
  services: string[];
  organisation: Organisation;
  department: Department;
  jobRole?: string;
  startDate?: Date;
  terminationDate?: Date;
  contentLanguageCode?: string;
  customFields?: Record<string, CustomFieldValue>;
}

export interface DeleteTenantUserPayload {
  organisation: string;
  name: string;
  userId: string;
  tenant: string;
}

export interface BulkDeleteTenantUsersPayload {
  usersIds: string[];
  tenant: string;
}

export interface BulkInviteTenantUsersPayload {
  usersToInvite: { id: string; invitationStatus: InvitationStatus }[];
}

export interface BulkUpdateTenantUsersPayload {
  usersToUpdate: {
    id: string;
    role?: string;
    services?: string[];
    organisation?: Organisation;
    department?: Department;
  }[];
}
export interface BulkUpdateUserPayload {
  formData: FormData;
  tenant: string;
}

export type AddUserFormData = {
  firstName: string;
  lastName: string;
  email: string;
  displayName: string;
  role: string;
  organisation: Organisation;
  department: Department;
  jobRole?: string;
  startDate?: Date;
  terminationDate?: Date;
  contentLanguageCode?: { code: string; name: string };
  customFields?: Record<string, CustomFieldValue>;
};

export enum UserDetailsStats {
  followers = "followers",
  following = "following",
  kudos = "kudos",
  posts = "posts",
  reactions = "reactions"
}

export type UserService = "paths" | "worlds";

export enum UserRole {
  Admin = "admin",
  OrgAdmin = "orgAdmin",
  Manager = "manager",
  Creator = "creator",
  User = "user"
}

export type UserBadge = {
  imageUrl: string;
  userBadgeId: string;
};

export interface UserProfileSummary {
  interests: string[];
  aboutMe: string;
  aboutRole: string;
  homeLocation?: string;
  officeLocation?: string;
  dateOfBirth?: string;
  avatarUrl?: string;
  coverImageUrl?: string;
  departmentId: string;
  departmentName: string;
  displayName: string;
  firstName: string;
  id: string;
  isOnboardingComplete?: boolean;
  lastName: string;
  organisationId: string;
  organisationName: string;
  jobRole?: string;
  userRole?: UserRole;
  services: UserService[];
  isSuspended: boolean;
  stats: Record<keyof typeof UserDetailsStats, number>;
  badge?: UserBadge;
}
export interface TenantsServicesRepsonse {
  activeServices: ActiveServices;
}
export interface ActiveServices {
  [key: string]: boolean;
}

export interface TenantsEmojisMoodRepsonse {
  surveyMoodQuestionOptions: MoodQuestionOptions[];
}
export interface MoodQuestionOptions {
  [key: string]: string;
}

export type UserWithName = TenantUserResponse & { name: string; rightContent?: ReactNode };

export function isUserWithName(obj: any): obj is UserWithName {
  return obj.role !== undefined && obj.departmentName !== undefined;
}

export interface UserProfileCourses {
  id: string;
  organisationUserId: string;
  courseId: string;
  courseVersion: number;
  status: CourseProgressStatus;
  assignedOn: string;
  startedOn: string;
  completedOn: string;
  name: string;
  modules?: {
    moduleId: string;
    status: CourseProgressStatus;
    completionStatus?: CourseCompletionStatus;
  }[];
}

export interface CourseAssignmentStatusResult {
  id: string;
  courseId: string;
  courseName?: string;
  organisationUserId: string;
  displayName?: string;
  avatarUrl?: string;
  email?: string;
  organisationId?: string;
  organisationName?: string;
  departmentId?: string;
  departmentName?: string;
  status?: CourseProgressStatus;
  courseInstanceId?: string;
  courseVersion?: number;
  assignedOn?: Date;
  assignmentType?: AssignmentType;
  startedOn?: Date;
  completedOn?: Date;
  cancelledOn?: Date;
  currentModuleName?: string;
}

export enum CourseStatusTypes {
  progress = "inProgress",
  completed = "completed",
  pending = "pending",
  cancelled = "cancelled",
  expired = "expired",
  notStarted = "notStarted"
}

export enum CustomFieldType {
  Text = "text",
  Number = "number",
  Date = "date"
}

export interface CustomField {
  displayName: string;
  type: CustomFieldType;
}

export interface CustomFieldMap {
  [key: string]: CustomField;
}

export interface CustomFieldDefinitions {
  fields: CustomFieldMap;
}

export type CustomFieldValue = string | number | Date;
